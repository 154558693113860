require('popper.js')
require('jquery')
require('bootstrap')
var logo, servicePrototypes;
function scroll(){
	if ($(this).scrollTop() > (window.innerHeight -100)) {
		$('.navbar').removeClass('nobg');
		$('#logoHeader').fadeIn('500');
	} else {
		$('.navbar').addClass('nobg');
		 $('#logoHeader').fadeOut('500');
	}
}
$(document).ready(function(){
scroll();
	$('.cta').wrap('<a href="mailto:work@redhoteskimo.com?subject=Website enquiry"></a>');
	
		document.getElementById("logo").innerHTML = null;
		
		$('.navbar').addClass('nobg');
		 $('#logoHeader').hide();
		$(window).scroll(function(){                          
           scroll();
        });

		logo = bodymovin.loadAnimation({
		  container: document.getElementById("logo"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: false,
		  path: "/assets/logo.json"
		});
		logo.playSegments([[0,249],[53,249]], true);

		$('.arrow').click(function(){
			$("html, body").animate({scrollTop:$(this).closest('.section').next().position().top-66});
		})



		//services
		servicePrototypes = bodymovin.loadAnimation({
		  container: document.getElementById("prototypes"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: true,
		  path: "/assets/prototypes.json"
		});
		//development
		servicePrototypes = bodymovin.loadAnimation({
		  container: document.getElementById("development"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: true,
		  path: "/assets/development.json"
		});
		//development
		servicePrototypes = bodymovin.loadAnimation({
		  container: document.getElementById("consultancy"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: true,
		  path: "/assets/consultancy.json"
		});
		//branding
		servicePrototypes = bodymovin.loadAnimation({
		  container: document.getElementById("branding"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: true,
		  path: "/assets/branding.json"
		});
		//animation
		servicePrototypes = bodymovin.loadAnimation({
		  container: document.getElementById("animation"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: true,
		  path: "/assets/animation.json"
		});
		//design
		servicePrototypes = bodymovin.loadAnimation({
		  container: document.getElementById("design"), // the dom element that will contain the animation
		  renderer: 'svg',
		  loop: true,
		  autoplay: true,
		  path: "/assets/design.json"
		});	
	
})
